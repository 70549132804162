<template>
  <el-row :gutter="20">
    <el-col :span="6" v-for="item in data" :key="item.ID">
      <topic-card :model="item"></topic-card>
    </el-col>
  </el-row>
</template>

<script>
import dThemeApi from '@/api/dTheme'
import setting from '@/setting'
import TopicCard from '@/components/topic-card'
export default {
  name: 'Search_dTheme',
  components: {
    TopicCard
  },
  data() {
    return {
      loading: false,
      data: [],
      page: {
        index: 1,
        size: 10,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      this.loading = true
      dThemeApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres: JSON.stringify([
          {name: "Theme_Type", value: "普通", displayType: "select"}
        ])
      }).then(res => {
        this.loading = false
        res.rows.forEach(item => {
          item.ex_pic = item.Pic ? settings.baseUrl.img + '/' + item.Pic : ''
        })
        this.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
</style>
